let Trix  = require("trix")

Trix.config.textAttributes.underline = {
    tagName: 'u'
}
const {lang} = Trix.config;

Trix.config.toolbar = {
    getDefaultHTML() { return `\
        <div class="trix-button-row">
          <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
            <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
            <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
            <button type="button" class="trix-button" data-trix-attribute="underline" title="h3" tabindex="-1"><u>U</u></button>
          </span>
          <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
            <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
            <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
          </span>
          <span class="trix-button-group-spacer"></span>
        </div>\
`; }
};

document.addEventListener("trix-file-accept", function(event) {
    event.preventDefault();
});