import $ from 'jquery';

$(document).on('ready turbolinks:load', function() {

    $(".btn-add-new").click(function () {
        $('.collapse').collapse('hide');
    });

    $(".btn-edit").click(function () {
        $('.collapse').collapse('hide');
    });
});
