// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "bootstrap-datepicker"
import "bootstrap-select"
import "@fortawesome/fontawesome-free/js/all"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css"
import "bootstrap-select/dist/css/bootstrap-select.min.css"
import jQuery from "jquery"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix");
require("@rails/actiontext")

require("custom/trix.js");
require("custom/profiles.js");
require("custom/resumes.js");
global.$ = jQuery;
